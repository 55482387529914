<template>
  <div class="sc-base">
    <div style="height: 80px"></div>
    <banner title="港口仓储" subTitle="Product Center" />
    <div class="body-box">
      <div class="c-width cf w1200">
        <div class="l-menu fl">
          <p class="menu-name">{{ data[index].title }}</p>
          <p
            v-for="(item, index) in data"
            :key="item.id"
            class="link"
            :class="[item.id == fid ? 'on' : '']"
            @click="selLink({ index }, item.id)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="r-box fr">
          <div class="r-header cf">
            <h4 class="fl">{{ data[index].title }}</h4>
            <div class="fr path">首页 > 新闻中心 > {{ data[index].title }}</div>
          </div>
          <div class="r-context">
            <div class="item1" v-if="index == 0">
                <img class="title-img" src="../../../assets/images/scjd01_img.png" alt="" />
                <p class="text">{{ data[index].text}}</p>
                <img class="context-img" :src="data[index].img" alt="">
            </div>
             <div class="item1" v-if="index == 1">
                <img class="title-img" :src="data[index].img" alt="" />
                <p class="text">{{ data[index].text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../../storage/data/data";
import banner from "@/components/banner";
export default {
  components: {
    banner,
  },
  data() {
    return {
      data: data,
      fid: 2,
      index: 1,
    };
  },
  methods: {
    selLink: function (index, fid) {
      this.index = index.index;
      this.fid = fid;
      if(index.index == 0){
          return
      }
      this.$router.push('/production-base')
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>